import React from 'react';
import Home from './Home';
import Footer from './Footer';
import Contact from './Contact';
import Services from './Services';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { BrowserRouter as Router, Routes, Route, NavLink } from 'react-router-dom';

function App() {
  const activeStyle = {
    textDecoration: 'none',
    color: 'white',
    borderBottom: '3px solid white'
  };

  return (
    <Router>
      <AppBar position="static">
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <img 
              src={`${process.env.PUBLIC_URL}/ssslogo2.png`} 
              alt="Scotian Software Systems Logo" 
              style={{ marginRight: '10px', width: '110px', height: '110px' }} 
            />
            <Typography variant="h6" component="div">
              Scotian Software Systems
            </Typography>
          </Box>
          <Box>
            <NavLink to="/" style={({ isActive }) => isActive ? activeStyle : { textDecoration: 'none', color: 'white', margin: '0 10px' }}>Home</NavLink>
            <NavLink to="/services" style={({ isActive }) => isActive ? activeStyle : { textDecoration: 'none', color: 'white', margin: '0 10px' }}>Services</NavLink>
            <NavLink to="/contact" style={({ isActive }) => isActive ? activeStyle : { textDecoration: 'none', color: 'white', margin: '0 10px' }}>Contact</NavLink>
          </Box>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;

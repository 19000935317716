import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Button, Paper } from '@mui/material';

const ServiceItem = ({ title, description }) => (
    <Paper style={{ padding: 16, margin: 16 }}>
      <Typography variant="h6">{title}</Typography>
      {description}
    </Paper>
  );
  

const Services = () => {
    const navigate = useNavigate();
    return (
        <Box p={3}>
        <Box textAlign="center" mb={4}>  {/* Added bottom margin here */}
            <Typography variant="h4" gutterBottom>
                Our Services
            </Typography>
            <Typography variant="body1" gutterBottom>
                Our Expertise, Your Vision - Specializing in comprehensive software development and bespoke consultancy, we transform your innovative ideas into dynamic digital realities.
            </Typography>
        </Box>

            <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
        <ServiceItem 
            title="Expertise in Diverse Technologies"
            description={
                <React.Fragment>
                  <Typography>
                    At Scotian Software Systems, we pride ourselves on our proficiency across a wide spectrum of cutting-edge technologies. Our team is highly skilled in various programming languages and frameworks, ensuring that we can adapt to any project requirement with ease. Our technological expertise includes:
                  </Typography>
                  <ul>
                    <li>Operating Systems: Mastery in Linux, OpenBSD, FreeBSD, and macOS.</li>
                    <li>Programming Languages: Proficient in Python, PHP, and Go for scalable and efficient software solutions.</li>
                    <li>Web Development: Expertise in HTML, CSS, and JavaScript for top-notch front-end development.</li>
                    <li>Frameworks: Specialization in Django and Flask for Python, and Gin for Go.</li>
                    <li>JavaScript Libraries: Proficiency in Vue.js and React.js for dynamic web interfaces.</li>
                    <li>Containerization and Orchestration: Utilizing Docker and Kubernetes for efficient software deployment and management.</li>
                    <li>Cloud Services: In-depth knowledge of Azure and AWS for scalable cloud solutions.</li>
                    <li>Blockchain Technology: Expertise in blockchain for solutions like smart contracts and decentralized applications.</li>
                  </ul>
                </React.Fragment>
              }
              
        />
        </Grid>
        {/* Domain Purchasing */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Domain Purchasing" 
            description="Assistance in selecting and acquiring the perfect domain name to reflect your brand and ensure a strong online presence." 
            />
        </Grid>

        {/* Hosting Services */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Hosting Services" 
            description="Reliable and scalable hosting solutions ensuring optimal website performance, security, and uptime." 
            />
        </Grid>

        {/* Web Design */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Web Design" 
            description="Crafting visually appealing and user-friendly designs with intuitive navigation and responsive layouts." 
            />
        </Grid>

        {/* Software Development */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Software Development" 
            description="Custom software solutions that are robust, efficient, and scalable, tailored to meet unique business requirements." 
            />
        </Grid>

        {/* Iterative Changes */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Iterative Changes" 
            description="Agile development approach allowing for flexible and continuous improvements based on changing needs and feedback." 
            />
        </Grid>

        {/* Single Page Applications */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Single Page Applications" 
            description="Building fast, responsive SPAs with a seamless user experience, resembling native app efficiency and speed." 
            />
        </Grid>

        {/* Full-Fledged Web Applications */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Full-Fledged Web Applications" 
            description="Comprehensive web applications that are feature-rich, secure, and scalable, catering to a broad range of business functions." 
            />
        </Grid>

        {/* Consultancy on Existing Projects */}
        <Grid item xs={12} md={6}>
            <ServiceItem 
            title="Consultancy on Existing Projects" 
            description="Insightful consultancy services with evaluations, strategic planning, and optimization solutions for ongoing projects." 
            />
        </Grid>

        {/* 24/7 Support */}
        <Grid item xs={12}>
            <ServiceItem 
            title="24/7 Support" 
            description="Dedicated round-the-clock support to promptly address any issues, minimizing downtime and enhancing user experience." 
            />
        </Grid>
        </Grid>


        {/* Call to Action */}
        <Box mt={4} textAlign="center">
            <Button 
            variant="contained" 
            color="primary" 
            onClick={() => navigate('/contact')}
            >
            Contact us now for a free consultation!
            </Button>
        </Box>
    </Box>
);};

export default Services;

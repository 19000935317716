import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003366', // Dark blue color
    },
    // You can define other colors and theme settings here
  },
  // Add other theme overrides if needed
});

export default theme;

import React from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigate = useNavigate();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={10} md={8} lg={7}>
        {/* Logo and Introduction */}
        <Box textAlign="center" p={3}>
          <Box my={4}>
            <img 
                src={`${process.env.PUBLIC_URL}/ssslogo2.png`} 
                alt="Scotian Software Systems Logo" 
                style={{ maxWidth: '200px', height: 'auto', margin: 'auto' }} 
            />
          </Box>
          <Typography variant="h4" gutterBottom>
              Welcome to Scotian Software Systems
          </Typography>
          <Typography variant="body1" gutterBottom>
              Your trusted partner in innovative and efficient software development solutions. We deliver cutting-edge applications tailored to your business needs, ensuring high performance, security, and user-centric design.
          </Typography>
          <Box mt={4}>
              <Button 
              variant="contained" 
              color="primary" 
              onClick={() => navigate('/services')}
              >
              Explore Our Services
              </Button>
          </Box>
        </Box>

        {/* Company Values Section */}
        <Paper elevation={2} style={{ padding: '20px', margin: '20px 0' }}>
          <Typography variant="h5" gutterBottom>
            Our Core Values - The Heart of Our Operations
          </Typography>
          <Typography variant="body1" paragraph>
            At Scotian Software Systems, we are passionately committed to pioneering advancements in the world of technology, continuously pushing the boundaries of what's possible in software development. Our ethos is not just rooted in innovation; it extends to a profound dedication to upholding the highest standards of quality and ethical practices in every project we undertake.
          </Typography>
          <Typography variant="body1" paragraph>
            As a trusted partner in software development and consultancy, we place immense value on understanding our clients' unique needs and challenges. Our solutions are crafted not just to meet but exceed expectations, delivering outcomes that are tailored, effective, and future-proof. Each project we embark on is an opportunity to demonstrate our unwavering commitment to precision, care, and excellence.
          </Typography>
          <Typography variant="body1" paragraph>
            At Scotian Software Systems, we believe in building long-lasting relationships with our clients, rooted in trust, transparency, and mutual respect. Our team of experts is not just technically proficient but also deeply empathetic to our clients' visions, ensuring that we are not just service providers but true partners in their journey towards digital transformation.
          </Typography>
        </Paper>

        {/* Call to Action */}
        <Box mt={4} textAlign="center">
              <Button 
              variant="contained" 
              color="primary" 
              onClick={() => navigate('/contact')}
              >
              Contact us now for a free consultation!
              </Button>
          </Box>
      </Grid>
    </Grid>
  );
};

export default Home;

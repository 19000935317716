import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ 
      width: '100%', 
      height: '34px', 
      backgroundColor: 'white', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center' 
    }}>
      <Typography variant="caption" style={{ color: 'grey' }}>
        Scotian Software Systems - You'll be glad you called us
      </Typography>
    </Box>
  );
}

export default Footer;

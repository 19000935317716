import React, { useState } from 'react';
import { Paper, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: 'Hello, I am interested in getting a FREE consultation for high quality expert software development or consultancy services from Scotian Software Systems. Please contact me at the information I\'ve provided above.'
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://dd81884vv1.execute-api.us-east-1.amazonaws.com/prod/contact', JSON.stringify(formData));
      if (response.status === 200) {
        setIsSubmitted(true);
        setTimeout(() => navigate('/'), 5000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  if (isSubmitted) {
    return (
      <Typography variant="h6" style={{ textAlign: 'center' }}>
        Thank you! Your message has been sent. One of our experts will be in touch with you shortly.
      </Typography>
    );
  }

  return (
    <Paper elevation={2} style={{ padding: '20px', margin: '70px', textAlign: 'center' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        Let's Build Something Together!
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField name="name" label="Name" fullWidth margin="normal" onChange={handleChange} />
        <TextField name="email" label="Email" fullWidth margin="normal" onChange={handleChange} />
        <TextField name="phone" label="Phone (Optional)" fullWidth margin="normal" onChange={handleChange} />
        <TextField name="message" label="Message" multiline rows={4} fullWidth margin="normal" defaultValue={formData.message} onChange={handleChange} />
        <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
          Submit
        </Button>
      </form>
    </Paper>
  );
};

export default Contact;
